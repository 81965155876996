import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import SwiperCore, { Pagination, Navigation, A11y } from 'swiper';
import Carousel, { consts } from 'react-elastic-carousel';
import ButtonMui from '@material-ui/core/Button';

import Header from '../../components/HeaderView';

import { ReactComponent as RightArrow } from '../../assets/arrow-right-white.svg';
import { ReactComponent as LeftArrow } from '../../assets/arrow-left-white.svg';
import style from './styles';

import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar';

import useUserContext from '../../hooks/useUserContext';
import useConfigContext from '../../hooks/useConfigContext';

import './style.css';
import styles from './styles.module.scss';
import globalStyles from '../../styles/global.module.scss';
import Maintenance from '../../components/templates/maintenance';

SwiperCore.use([Navigation, Pagination, A11y]);

const stylesTheme = theme => style(theme);

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    padding: '0px',
    minWidth: 'auto',
    zIndex: 1,
  },
  endIcon: {
    marginLeft: 3,
  },
})(ButtonMui);

function Home() {
  const { userState } = useUserContext();
  const { configState } = useConfigContext();
  const { deliveries, swimwear, shoes } = configState;
  const seasonName = configState.season.name || '';
  const homeConfig = configState.home;

  const [wid, setWid] = useState(window.innerWidth);

  const isAuthenticated = Boolean(userState.token);

  // const isAdminOrSelectedEmails = useMemo(
  //   () =>
  //     userState.user?.admin ||
  //     userState.user?.email?.includes('nordstrom') ||
  //     userState.user?.email?.includes('anthropologie') ||
  //     userState.user?.email?.includes('saks'),
  //   [userState.user.admin, userState.user.email]
  // );

  // const displayShoes = isAdminOrSelectedEmails;

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 400, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    // { width: 1200, itemsToShow: 4 },
  ];

  const updateViewportWidth = useCallback(() => {
    setWid(prevWidth => {
      const shouldUpdate =
        (prevWidth > 1024 && window.innerWidth < 1024) ||
        (prevWidth < 1024 && window.innerWidth > 1024);
      if (shouldUpdate) return window.innerWidth;
      return prevWidth;
    });
  }, [setWid]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', updateViewportWidth);
    return () => {
      window.removeEventListener('resize', updateViewportWidth);
    };
  }, [updateViewportWidth]);

  const DeliveryCard = ({
    id,
    backgroundUrl,
    subTitle1,
    subTitle2,
    title,
    url,
    upperTitle,
    singleDelivery,
    collection,
  }) => {
    return (
      <div
        style={{ width: singleDelivery && '99%' }}
        className={styles.cardWrapper}
      >
        <Link
          className={`${globalStyles.link}  ${
            singleDelivery ? styles.singleDeliveryCard : styles.deliveryCard
          }`}
          key={id}
          style={{
            backgroundImage: `url(${backgroundUrl})`,
          }}
          to={`${url}`}
        >
          <div
            className={styles.deliveryCardTextWrapper}
            style={{ left: singleDelivery && '0.5%' }}
          >
            <div className={style.upperTitle}>{upperTitle}</div>
            <span style={{ position: 'relative', left: 5 }}>{collection} </span>
            <div style={{ position: 'relative', bottom: 10 }}>
              <h3>{title}</h3>
              <h4>{`${subTitle1 || ''} | ${subTitle2 || ''}`}</h4>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const renderArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <LeftArrow /> : <RightArrow />;

    return (
      <StyledButton
        onClick={onClick}
        disabled={isEdge}
        id={type === 'NEXT' ? 'RightArrow' : 'LeftArrow'}
      >
        {pointer}
      </StyledButton>
    );
  };

  const Sustainability = () => {
    const backgroundMobile = isAuthenticated
      ? homeConfig.sustainability.bgMobSignedIn
      : homeConfig.sustainability.bgMobNotSignedIn;
    const backgroundDesk = isAuthenticated
      ? homeConfig.sustainability.bgDeskSignedIn
      : homeConfig.sustainability.bgDeskNotSignedIn;

    return (
      <section
        style={{ height: window.innerHeight }}
        className={styles.section}
      >
        <picture className={styles.background}>
          <source media="(max-width:1024px)" srcSet={backgroundMobile} />
          <img src={backgroundDesk} height={window.innerHeight} alt="" />
        </picture>

        <div className={styles.boxedContent}>
          <div className={styles.textBox}>
            <h2>Sustainability</h2>
            <p>
              Nature has always been your home. We are passionate about taking
              impactful steps to ensure it will keep inpiring new generations
              with its beauty and diversity.
            </p>
          </div>
          <Link className={styles.callToAction} to="/sustainability">
            Discover
          </Link>
        </div>
      </section>
    );
  };

  const WhoWeAre = () => {
    const backgroundMobile = isAuthenticated
      ? homeConfig.whoWeAre.bgMobSignedIn
      : homeConfig.whoWeAre.bgMobNotSignedIn;
    const backgroundDesk = isAuthenticated
      ? homeConfig.whoWeAre.bgDeskSignedIn
      : homeConfig.whoWeAre.bgDeskNotSignedIn;

    return (
      <section
        style={{ height: window.innerHeight }}
        className={styles.section}
      >
        <picture className={styles.background}>
          <source media="(max-width:1024px)" srcSet={backgroundMobile} />
          <img src={backgroundDesk} height={window.innerHeight} alt="" />
        </picture>

        <div className={styles.boxedContent}>
          <div className={styles.textBox}>
            <h2>Who we are</h2>
            <p>
              We are a group of creatives from Rio de Janeiro, Brazil, that
              believe in the existence of happiness. More than a brand, we
              create enlightened cultural movements since 1997.
            </p>
          </div>
          <Link className={styles.callToAction} to="/whoweare">
            Discover
          </Link>
        </div>
      </section>
    );
  };

  const WelcomeSS2022 = () => {
    const backgroundMobile = isAuthenticated
      ? homeConfig.welcome.bgMobSignedIn
      : homeConfig.welcome.bgMobNotSignedIn;
    const backgroundDesk = isAuthenticated
      ? homeConfig.welcome.bgDeskSignedIn
      : homeConfig.welcome.bgDeskNotSignedIn;

    return (
      <section
        style={{ height: window.innerHeight }}
        className={styles.section}
      >
        <picture className={styles.background}>
          <source media="(max-width:1024px)" srcSet={backgroundMobile} />
          <img src={backgroundDesk} height={window.innerHeight} alt="" />
        </picture>

        <div className={styles.centralizedContent}>
          <h2>Welcome to {seasonName}</h2>
          <Link className={styles.callToAction} to="/delivery/1/view">
            See the collection
          </Link>
        </div>
      </section>
    );
  };

  const Deliveries = () => {
    return (
      <section className={styles.section}>
        {/* <h2>Collections</h2>
        {wid > 1024 && (
          <>
            <div
              className={styles.deliveriesWrapper}
              style={{ minHeight: 1000 }}
            >
              {collections.map(delivery => {
                const {
                  backgroundUrl,
                  url,
                  subTitle1,
                  subTitle2,
                  title,
                  active,
                  id,
                  collection,
                } = delivery;
                if (!active) return null;
                return (
                  <DeliveryCard
                    backgroundUrl={backgroundUrl}
                    url={url}
                    subTitle1={subTitle1}
                    subTitle2={subTitle2}
                    title={title}
                    id={id}
                    key={id}
                    collection={collection}
                  />
                );
              })}
            </div>
          </>
        )} */}
        {/* {wid < 1024 && (
          <Carousel
            breakPoints={breakPoints}
            className={styles.deliveriesWrapper}
            renderArrow={renderArrow}
            itemsToScroll={3}
            showArrows={!(wid < 1000)}
            enableMouseSwipe={wid < 1000}
          >
            {collections.map(delivery => {
              const {
                backgroundUrl,
                url,
                subTitle1,
                subTitle2,
                title,
                active,
                id,
                collection,
              } = delivery;
              if (!active) return null;
              return (
                <DeliveryCard
                  backgroundUrl={backgroundUrl}
                  url={url}
                  subTitle1={subTitle1}
                  subTitle2={subTitle2}
                  title={title}
                  id={id}
                  key={id}
                  collection={collection}
                />
              );
            })}
          </Carousel>
        )} */}

        <h2 style={{ margin: '8px 0px' }}>Ready to Wear</h2>

        {wid > 1024 && (
          <>
            <div
              className={styles.deliveriesWrapper}
              style={{ minHeight: 1000 }}
            >
              {deliveries.map(delivery => {
                const {
                  backgroundUrl,
                  url,
                  subTitle1,
                  subTitle2,
                  title,
                  active,
                  id,
                  collection,
                } = delivery;
                if (!active) return null;
                return (
                  <DeliveryCard
                    backgroundUrl={backgroundUrl}
                    url={url}
                    subTitle1={subTitle1}
                    subTitle2={subTitle2}
                    title={title}
                    id={id}
                    key={id}
                    collection={collection}
                  />
                );
              })}
            </div>
          </>
        )}
        {wid < 1024 && (
          <Carousel
            breakPoints={breakPoints}
            className={styles.deliveriesWrapper}
            renderArrow={renderArrow}
            itemsToScroll={3}
            showArrows={!(wid < 1000)}
            enableMouseSwipe={wid < 1000}
          >
            {deliveries.map(delivery => {
              const {
                backgroundUrl,
                url,
                subTitle1,
                subTitle2,
                title,
                active,
                id,
                collection,
              } = delivery;
              if (!active) return null;
              return (
                <DeliveryCard
                  backgroundUrl={backgroundUrl}
                  url={url}
                  subTitle1={subTitle1}
                  subTitle2={subTitle2}
                  title={title}
                  id={id}
                  key={id}
                  collection={collection}
                />
              );
            })}
          </Carousel>
        )}

        <h2 style={{ margin: '8px 0px' }} className={styles.swimwearTitle}>
          Swimwear
        </h2>
        <div
          className={`${styles.deliveriesWrapper} ${styles.singleDeliveryWrapper}`}
        >
          {swimwear.map(delivery => {
            const {
              backgroundUrl,
              url,
              // subTitle1,
              // subTitle2,
              title,
              active,
              id,
              upperTitle,
            } = delivery;
            if (!active) return null;
            return (
              <DeliveryCard
                backgroundUrl={backgroundUrl}
                url={url}
                // subTitle1={subTitle1}
                // subTitle2={subTitle2}
                title={window.innerWidth > 1024 && title}
                id={id}
                key={id}
                upperTitle={upperTitle}
                singleDelivery
              />
            );
          })}
        </div>

        <>
          <h2 className={styles.swimwearTitle}>Shoes</h2>
          <div
            className={`${styles.deliveriesWrapper} ${styles.singleDeliveryWrapper}`}
          >
            {shoes.map(delivery => {
              const {
                backgroundUrl,
                url,
                // subTitle1,
                // subTitle2,
                title,
                active,
                id,
                upperTitle,
              } = delivery;
              if (!active) return null;
              return (
                <DeliveryCard
                  backgroundUrl={backgroundUrl}
                  url={url}
                  // subTitle1={subTitle1}
                  // subTitle2={subTitle2}
                  title={title}
                  id={id}
                  key={id}
                  upperTitle={upperTitle}
                  singleDelivery
                />
              );
            })}
          </div>
        </>
      </section>
    );
  };

  const Interested = () => {
    return (
      <>
        <section
          style={{ height: window.innerHeight }}
          className={`${styles.section} ${styles.noBackground}`}
        >
          <div className={styles.centralizedContent}>
            <h2>Interested in selling FARM Rio?</h2>
            <p>
              Complete our application and you&apos;ll receive an answer
              shortly.
            </p>
            <Link className={styles.callToAction} to="/register">
              Apply now
            </Link>
          </div>
        </section>
      </>
    );
  };

  const siteIsMaintenanceMode =
    process.env.REACT_APP_MAINTENANCE_MODE === 'enabled' &&
    !userState.user.admin;

  return (
    <>
      <Header />
      {siteIsMaintenanceMode ? (
        <Maintenance />
      ) : !isAuthenticated ? (
        <>
          <WhoWeAre />
          <WelcomeSS2022 />
          <Deliveries />
          <Interested />
          <Sustainability />
        </>
      ) : (
        isAuthenticated && (
          <>
            <WelcomeSS2022 />
            <Deliveries />
            <WhoWeAre />
            <Sustainability />
          </>
        )
      )}
    </>
  );
}

export default withStyles(stylesTheme)(Home);
