import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import history from '../../services/history';

export default function LeftLateralBar({ by, delivery, bg, DELIVERIES }) {
  return (
    <Grid
      container
      justifyContent="space-around"
      style={{
        transform: 'rotate(-90deg)',
        width:
          window.innerHeight < 650 ? 500 : window.innerHeight > 750 ? 624 : 545,

        position: 'fixed',
        left:
          window.innerHeight < 650
            ? -215
            : window.innerHeight > 750
            ? -277
            : -242,
        top: window.innerHeight < 650 ? '53.5%' : '50%',

        // backgroundColor: 'red',
      }}
    >
      {/* <>
        <Grid
          onClick={() => {
            if (history.location.pathname.includes('europe')) {
              history.replace(`/europe/delivery/4/view`);
            } else if (history.location.pathname.includes('swimwear'))
              history.replace(`/swimwear/3/view`);
            else if (history.location.pathname.includes('shoes')) {
              history.replace(`/shoes/3/view`);
            } else {
              history.replace(`/delivery/4/view`);
            }
          }}
          style={{
            height: 30,
            // width: 160,

            cursor: 'pointer',
          }}
        >
          <Typography
            // variant="h6"
            style={{
              textAlign: 'center',

              fontSize: 14,
              color:
                by === 'view' || by === 'smiley'
                  ? bg
                    ? '#ffffff'
                    : '#000000'
                  : '#000000',
              border:
                delivery === '4' //eslint-disable-line
                  ? bg && (by === 'view' || by === 'smiley')
                    ? '2px solid #ffffff'
                    : '2px solid #000000'
                  : '2px solid transparent',
              padding: window.innerHeight > 650 ? '4px 10px' : '4px 4px',

              fontFamily: delivery === '4' && 'GalanoGrotesque-Medium',
              fontWeight: delivery === '4' ? 'semibold' : '',
            }}
          >
            SKI
          </Typography>
          {delivery === '4' && (
            <Typography
              style={{
                position: 'relative',
                top: 10,
                left: 5,

                textAlign: 'center',
                // width: 140,

                fontSize: 12,
                color:
                  by === 'view' || by === 'smiley'
                    ? bg
                      ? '#ffffff'
                      : '#000000'
                    : '#000000',
              }}
            >
              {DELIVERIES[3].subTitle2.split('Shipping ')[1]}
            </Typography>
          )}
        </Grid>
      </> */}
      {history.location.pathname.includes('delivery') && (
        <>
          <Grid
            onClick={() => {
              if (history.location.pathname.includes('europe')) {
                history.replace(`/europe/delivery/3/${by}`);
              } else if (history.location.pathname.includes('swimwear'))
                history.replace(`/swimwear/3/${by}`);
              else if (history.location.pathname.includes('shoes')) {
                history.replace(`/shoes/3/${by}`);
              } else {
                history.replace(`/delivery/3/${by}`);
              }
            }}
            style={{
              height: 30,
              // width: 160,

              cursor: 'pointer',
            }}
          >
            <Typography
              // variant="h6"
              style={{
                textAlign: 'center',

                fontSize: 14,
                color:
                  by === 'view' || by === 'smiley'
                    ? bg
                      ? '#ffffff'
                      : '#000000'
                    : '#000000',
                border:
                delivery === '3' //eslint-disable-line
                    ? bg && (by === 'view' || by === 'smiley')
                      ? '2px solid #ffffff'
                      : '2px solid #000000'
                    : '2px solid transparent',
                padding: window.innerHeight > 650 ? '4px 10px' : '4px 4px',

                fontFamily: delivery === '3' && 'GalanoGrotesque-Medium',
                fontWeight: delivery === '3' ? 'semibold' : '',
              }}
            >
              Delivery 3
            </Typography>
            {delivery === '3' && (
              <Typography
                style={{
                  position: 'relative',
                  top: 10,
                  left: 5,

                  textAlign: 'center',
                  // width: 140,

                  fontSize: 12,
                  color:
                    by === 'view' || by === 'smiley'
                      ? bg
                        ? '#ffffff'
                        : '#000000'
                      : '#000000',
                }}
              >
                {DELIVERIES[2].subTitle2.split('Shipping ')[1]}
              </Typography>
            )}
          </Grid>
        </>
      )}

      <Grid
        onClick={() => {
          if (history.location.pathname.includes('europe')) {
            history.replace(`/europe/delivery/2/${by}`);
          } else if (history.location.pathname.includes('swimwear'))
            history.replace(`/swimwear/2/${by}`);
          else if (history.location.pathname.includes('weddingcapsule')) return;
          else if (history.location.pathname.includes('shoes')) {
            history.replace(`/shoes/2/${by}`);
          } else {
            history.replace(`/delivery/2/${by}`);
          }
        }}
        style={{
          height: 30,
          // width: 160,
          // marginLeft: 60,
          // marginRight: 60,
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',

            fontSize: 14,
            color:
              by === 'view' || by === 'smiley'
                ? bg
                  ? '#ffffff'
                  : '#000000'
                : '#000000',
            border:
              delivery === '2' //eslint-disable-line
                ? bg && (by === 'view' || by === 'smiley')
                  ? '2px solid #ffffff'
                  : '2px solid #000000'
                : '2px solid transparent',
            padding: window.innerHeight > 650 ? '4px 10px' : '4px 4px',

            fontFamily: delivery === '2' && 'GalanoGrotesque-Medium',
            fontWeight: delivery === '2' ? 'semibold' : '',
          }}
        >
          {' '}
          Delivery 2
        </Typography>
        {delivery === '2' && (
          <Typography
            style={{
              position: 'relative',
              top: 10,
              left: -2,
              marginLeft: 3,
              textAlign: 'center',
              // width: 140,

              fontSize: 12,
              color:
                by === 'view' || by === 'smiley'
                  ? bg
                    ? '#ffffff'
                    : '#000000'
                  : '#000000',
            }}
          >
            {DELIVERIES[1].subTitle2.split('Shipping ')[1]}
          </Typography>
        )}
      </Grid>

      {!window.location.pathname.includes('men') && (
        <Grid
          // container
          onClick={() => {
            if (history.location.pathname.includes('europe')) {
              history.replace(`/europe/delivery/1/${by}`);
            } else if (history.location.pathname.includes('swimwear'))
              history.replace(`/swimwear/1/${by}`);
            else if (history.location.pathname.includes('shoes')) {
              history.replace(`/shoes/1/${by}`);
            } else {
              history.replace(`/delivery/1/${by}`);
            }
          }}
          style={{
            // width: 160,

            height: 30,
            // paddingLeft: 130,

            cursor: 'pointer',
          }}
        >
          <Typography
            variant="h6"
            style={{
              // display: 'flex',
              // alignItems: 'flex-end',
              textAlign: 'center',
              fontSize: 14,
              // marginLeft: 60,
              // paddingLeft: -120,
              color:
                by === 'view' || by === 'smiley'
                  ? bg
                    ? '#ffffff'
                    : '#000000'
                  : '#000000',
              border:
                delivery === '1' //eslint-disable-line
                  ? bg && (by === 'view' || by === 'smiley')
                    ? '2px solid #ffffff'
                    : '2px solid #000000'
                  : '2px solid transparent',
              padding: window.innerHeight > 650 ? '4px 10px' : '4px 4px',

              fontFamily: delivery === '1' && 'GalanoGrotesque-Medium',
              fontWeight: delivery === '1' ? 'semibold' : '',
            }}
          >
            Delivery 1
          </Typography>
          {delivery === '1' && (
            <Typography
              style={{
                position: 'relative',
                top: 10,

                textAlign: 'center',
                // width: 140,

                fontSize: 12,
                color:
                  by === 'view' || by === 'smiley'
                    ? bg
                      ? '#ffffff'
                      : '#000000'
                    : '#000000',
              }}
            >
              {DELIVERIES[0].subTitle2.split('Shipping ')[1]}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}
