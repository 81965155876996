/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, Fade, Typography } from '@material-ui/core';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import Img from 'react-cool-img';
import { useDrag, useDrop } from 'react-dnd';
import Skeleton from '@material-ui/lab/Skeleton';
import ModalProduct from '../ModalProduct';
import BoardDND from '../BoardDND';
import { loadProducts, saveNewOrderDeliveries } from './api';

import useConfigContext from '../../hooks/useConfigContext';

const skeletonArray = Array(20).map((_, index) => ({ id: `skeleton${index}` }));

const productObjectToFilter = {
  delivery: 'MAIN',
  swimwear: 'SWIMWEAR',
  shoes: 'FOOTWEAR',
  men: 'MENSWEAR',
};

function DeliveryView({ bg, showDND, handleShowDND }) {
  const [orderedProducts, setOrderedProducts] = useState([]);
  const [capsulaProducts, setCapsulaProducts] = useState([]);
  const [preSpringProducts, setPreSpringProducts] = useState([]);
  const url = document.location.pathname;
  const [allProducts, setAllProducts] = useState([]);
  const { configState } = useConfigContext();
  const seasonName = configState.season.name || '';
  const { deliveries, swimwear, shoes } = configState;
  const [nordProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const ref = useRef();
  const capsulaBanner = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    if (window.location.search.includes('capsula=prefall')) {
      setTimeout(() => {
        capsulaBanner.current.scrollIntoView({ behavior: 'smooth' });
      }, 2000);
    }
  }, [window.location.search, capsulaBanner]);

  const [, dragRef] = useDrag({
    item: { type: 'CARD', id: 'sou um card' },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item, monitor) {},
  });
  dragRef(dropRef(ref));
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const currentCollection = useRef('');
  const splittedLocation = location.pathname.split('/');
  currentCollection.current = splittedLocation[1];

  const RenderProducts = () => {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          style={{
            paddingLeft: 15,
            width: 1044,
            marginTop: 100,
            height: '100%',
            // marginTop: window.innerHeight / 4,
          }}
        >
          {showDND ? (
            <BoardDND
              delivery_id={delivery_id}
              data={
                !document.location.pathname.includes('delivery')
                  ? orderedProducts
                  : allProducts
              }
              handleSaveProducts={saveProductsWithNewOrder}
            />
          ) : !loading ? (
            <Grid>
              {orderedProducts.length > 0 &&
                orderedProducts?.map(item => (
                  <Grid
                    item
                    key={String(item.mainly_image)}
                    md={item === 2 ? 12 : 3}
                    style={{ display: 'inline-block', position: 'relative' }}
                    onClick={async () => {
                      history.replace({
                        search: `?style_code=${item.style_code}`,
                      });
                      // setSelectedProduct(item);
                      // setOpen(true);
                    }}
                  >
                    {item.status === 'Sold Out' && (
                      <Typography
                        align="center"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: '#fff',
                          zIndex: 1000,
                          fontSize: 40,
                          letterSpacing: '0.1em',
                          lineHeight: '24px',
                          width: 252,
                        }}
                      >
                        SOLD OUT{' '}
                      </Typography>
                    )}

                    {item?.sustainableTags && (
                      <>
                        <Grid
                          container
                          style={{
                            position: 'absolute',
                            background: 'white',
                            padding: '0px 9px',
                            bottom: '10%',
                            width: 'fit-content',
                            opacity: 0.8,
                          }}
                        >
                          <Typography
                            style={{
                              position: 'relative',
                              bottom: 2,
                              fontFamily: 'GalanoGrotesque-Medium',
                              fontSize: 10,
                            }}
                          >
                            {item.sustainableTags[0]}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          style={{
                            position: 'absolute',
                            background: 'white',
                            bottom: '16%',
                            width: 'fit-content',
                            padding: '0px 9px',

                            opacity: 0.8,
                          }}
                        >
                          <Typography
                            style={{
                              position: 'relative',
                              bottom: 2,
                              fontFamily: 'GalanoGrotesque-Medium',
                              fontSize: 10,
                            }}
                          >
                            {item.sustainableTags[1]}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Img
                      style={{
                        margin: '1px 2px',
                        width: 252,
                        height: 380,
                        cursor: 'pointer',
                        filter: item.status === 'Sold Out' && 'brightness(65%)',
                      }}
                      src={
                        item.mainly_image ||
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
                      }
                      alt="a"
                    />
                  </Grid>
                ))}
              {capsulaProducts.length > 0 && (
                <Grid
                  ref={capsulaBanner}
                  style={{
                    position: 'relative',
                    bottom: 135,
                  }}
                >
                  <Grid item md={12}>
                    <Grid
                      style={{
                        position: 'relative',
                        top: 285,
                        zIndex: 2,
                      }}
                    >
                      <Typography
                        align="center"
                        style={{
                          fontSize: 48,
                          color: '#ffffff',
                          letterSpacing: '0.1em',
                          lineHeight: '48px',
                          fontWeight: 300,
                        }}
                      >
                        {capsulaProducts[0].capsula.toUpperCase()}
                      </Typography>

                      <Typography
                        align="center"
                        variant="h6"
                        style={{
                          color: '#ffffff',
                          fontSize: 18,
                          lineHeight: '32px',
                          letterSpacing: '0.1em',
                          paddingTop: 24,
                        }}
                      >
                        {document.location.pathname.includes('weddingcapsule')
                          ? deliveries[1].subTitle2.toUpperCase()
                          : document.location.pathname.includes('delivery')
                          ? deliveries[delivery_id - 1].subTitle2.toUpperCase()
                          : document.location.pathname.includes('shoes')
                          ? shoes[delivery_id - 1].subTitle2.toUpperCase()
                          : swimwear[delivery_id - 1].subTitle2.toUpperCase()}
                      </Typography>

                      <Typography
                        align="center"
                        variant="h6"
                        style={{
                          color: '#ffffff',
                          fontSize: 18,
                          lineHeight: '32px',
                          letterSpacing: '0.1em',
                        }}
                      >
                        {document.location.pathname.includes('weddingcapsule')
                          ? deliveries[1].subTitle1
                          : document.location.pathname.includes('delivery')
                          ? deliveries[delivery_id - 1].subTitle1
                          : document.location.pathname.includes('shoes')
                          ? shoes[delivery_id - 1].subTitle1.toUpperCase()
                          : swimwear[delivery_id - 1].subTitle1}
                      </Typography>
                    </Grid>
                    <img
                      style={{
                        height: '380px',
                        width: '99%',
                        position: 'relative',
                        left: '2px',
                        objectFit: 'cover',
                        objectPosition: 'top center',
                      }}
                      alt="capsula divider"
                      src={`https://storage.googleapis.com/showroom-bucket-images/${configState.season.line}/Capsula${delivery_id}/BG/Capsula${delivery_id}_BG.jpg`}
                    />
                  </Grid>
                  {capsulaProducts?.map(item => (
                    <Grid
                      item
                      key={String(item.mainly_image)}
                      md={item === 2 ? 12 : 3}
                      style={{ display: 'inline-block', position: 'relative' }}
                      onClick={async () => {
                        history.replace({
                          search: `?style_code=${item.style_code}`,
                        });
                        // setSelectedProduct(item);
                        // setOpen(true);
                      }}
                    >
                      {item.status === 'Sold Out' && (
                        <Typography
                          align="center"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#fff',
                            zIndex: 1000,
                            fontSize: 40,
                            letterSpacing: '0.1em',
                            lineHeight: '24px',
                            width: 252,
                          }}
                        >
                          SOLD OUT{' '}
                        </Typography>
                      )}

                      {item?.sustainableTags && (
                        <>
                          <Grid
                            container
                            style={{
                              position: 'absolute',
                              background: 'white',
                              padding: '0px 9px',
                              bottom: '10%',
                              width: 'fit-content',
                              opacity: 0.8,
                            }}
                          >
                            <Typography
                              style={{
                                position: 'relative',
                                bottom: 2,
                                fontFamily: 'GalanoGrotesque-Medium',
                                fontSize: 10,
                              }}
                            >
                              {item.sustainableTags[0]}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            style={{
                              position: 'absolute',
                              background: 'white',
                              bottom: '16%',
                              width: 'fit-content',
                              padding: '0px 9px',

                              opacity: 0.8,
                            }}
                          >
                            <Typography
                              style={{
                                position: 'relative',
                                bottom: 2,
                                fontFamily: 'GalanoGrotesque-Medium',
                                fontSize: 10,
                              }}
                            >
                              {item.sustainableTags[1]}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Img
                        style={{
                          margin: '1px 2px',
                          width: 252,
                          height: 380,
                          cursor: 'pointer',
                          filter:
                            item.status === 'Sold Out' && 'brightness(65%)',
                        }}
                        src={
                          item.mainly_image ||
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
                        }
                        alt="a"
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {preSpringProducts.length > 0 &&
                window.location.href.includes('delivery') && (
                  <Grid
                    ref={capsulaBanner}
                    style={{
                      position: 'relative',
                      bottom: delivery_id === '2' ? 271 : 135,
                    }}
                  >
                    <Grid item md={12}>
                      <Grid
                        style={{
                          position: 'relative',
                          top: 285,
                          zIndex: 2,
                        }}
                      >
                        <Typography
                          align="center"
                          style={{
                            fontSize: 48,
                            color: '#ffffff',
                            letterSpacing: '0.1em',
                            lineHeight: '48px',
                            fontWeight: 300,
                          }}
                        >
                          {preSpringProducts[0].capsula.toUpperCase()}
                        </Typography>

                        <Typography
                          align="center"
                          variant="h6"
                          style={{
                            color: '#ffffff',
                            fontSize: 18,
                            lineHeight: '32px',
                            letterSpacing: '0.1em',
                            paddingTop: 24,
                          }}
                        >
                          {document.location.pathname.includes('weddingcapsule')
                            ? deliveries[1].subTitle2.toUpperCase()
                            : document.location.pathname.includes('delivery')
                            ? deliveries[
                                delivery_id - 1
                              ].subTitle2.toUpperCase()
                            : document.location.pathname.includes('shoes')
                            ? shoes[delivery_id - 1].subTitle2.toUpperCase()
                            : swimwear[delivery_id - 1].subTitle2.toUpperCase()}
                        </Typography>

                        <Typography
                          align="center"
                          variant="h6"
                          style={{
                            color: '#ffffff',
                            fontSize: 18,
                            lineHeight: '32px',
                            letterSpacing: '0.1em',
                          }}
                        >
                          {document.location.pathname.includes('weddingcapsule')
                            ? deliveries[1].subTitle1
                            : document.location.pathname.includes('delivery')
                            ? deliveries[delivery_id - 1].subTitle1
                            : document.location.pathname.includes('shoes')
                            ? shoes[delivery_id - 1].subTitle1.toUpperCase()
                            : swimwear[delivery_id - 1].subTitle1}
                        </Typography>
                      </Grid>
                      <img
                        style={{
                          height: '380px',
                          width: '99%',
                          position: 'relative',
                          left: '2px',
                          objectFit: 'cover',
                          objectPosition: 'top center',
                        }}
                        alt="capsula divider"
                        src="https://storage.googleapis.com/showroom-bucket-images/RE25/Capsula2/BG/1_SHOP_NOW_pre_spring_december.jpg"
                      />
                    </Grid>
                    {preSpringProducts?.map(item => (
                      <Grid
                        item
                        key={String(item.mainly_image)}
                        md={item === 2 ? 12 : 3}
                        style={{
                          display: 'inline-block',
                          position: 'relative',
                        }}
                        onClick={async () => {
                          history.replace({
                            search: `?style_code=${item.style_code}`,
                          });
                          // setSelectedProduct(item);
                          // setOpen(true);
                        }}
                      >
                        {item.status === 'Sold Out' && (
                          <Typography
                            align="center"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: '#fff',
                              zIndex: 1000,
                              fontSize: 40,
                              letterSpacing: '0.1em',
                              lineHeight: '24px',
                              width: 252,
                            }}
                          >
                            SOLD OUT{' '}
                          </Typography>
                        )}

                        {item?.sustainableTags && (
                          <>
                            <Grid
                              container
                              style={{
                                position: 'absolute',
                                background: 'white',
                                padding: '0px 9px',
                                bottom: '10%',
                                width: 'fit-content',
                                opacity: 0.8,
                              }}
                            >
                              <Typography
                                style={{
                                  position: 'relative',
                                  bottom: 2,
                                  fontFamily: 'GalanoGrotesque-Medium',
                                  fontSize: 10,
                                }}
                              >
                                {item.sustainableTags[0]}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              style={{
                                position: 'absolute',
                                background: 'white',
                                bottom: '16%',
                                width: 'fit-content',
                                padding: '0px 9px',

                                opacity: 0.8,
                              }}
                            >
                              <Typography
                                style={{
                                  position: 'relative',
                                  bottom: 2,
                                  fontFamily: 'GalanoGrotesque-Medium',
                                  fontSize: 10,
                                }}
                              >
                                {item.sustainableTags[1]}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        <Img
                          style={{
                            margin: '1px 2px',
                            width: 252,
                            height: 380,
                            cursor: 'pointer',
                            filter:
                              item.status === 'Sold Out' && 'brightness(65%)',
                          }}
                          src={
                            item.mainly_image ||
                            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXu7u62trbx8fHPz8/t7e2zs7O8vLy5ubnl5eWrq6vc3Nzq6urZ2dnh4eHV1dW6urrHx8fBwcHKysqlpaXy5cTEAAAEF0lEQVR4nO3bjXKkKBQFYLj8CAIK5v3fdS/Y9mi3md1UZavFOt9UTTpgujwBQaEjBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEDH6N2nT+l3TfaNulNEGo18Y+KnT+sXUXkPKGW5USOGs4BShk+f1++JJ52Uu+l0m0akfN6GN+im66RA7jygNK7veYNcnBo1fJNQDmo9IrouQ2az+S4gt+ImdzjqlLMp0Pz5/6XGfvp8f4qm9xhmVimwNNmTys4GVv0+x5uStiGFKO1a+NGktq+EQtjXgGo3ZOpdG5tlaiPR0HvC+BJgu1U1C7eo6T+heQQkEYJ4vHzc6PD1F/pPaHI7fXKZi+2yzn60tLrBhdx/Qqlbmdpmi6WlCcN+pOk7YXvYpeU5fj4iqv2k0XVC48TLU/B6XYbbJGz3K/rQqi0PDXdJ2MYZd7iPMXW0OTxV9ZzQ1MuQjk/BrZvS/kLsOmEdV15uVNttKKm7tGEbOceTNlxuknBdrTiuRpn6PHi4Pe864VAnfMr7RmyhSd4loRlfG7HNkCLdZj5cb0t3U/464R+X4LpOKE1qiRw/2/M/Y9db7yTvk1DOayEllbNKdHZI5wkfj09itzx6HHm6T8gR9SEB6ZeA3SeUxnLn1FttuyRvlrA247aIz8/678uJN0hYB9FlinFa7NmacG8Jz/dE12X885re1kvP1rz/qrc17/pI/6OIZtb//p4XE6z5gdLh3hPfwEzqP5pSb1304eRTQt/49JkCAAAAAEBftHt/0D0rq9zFHhmfH1n7U0LHkrY749uC/lnZ/r3aMet+zjXoGEaVRN18UfG58hvVWBdGp6kGiDoqRy76aWxV9S8RjmXCRdLCjfwjiotDNOo6f66g/TBYHwUVX8y6cSaCNPwdTd5yDWkvZ+vHcfBz4apZSSv2ZbWfpi/H71Bo9Hnx0Q1+3Xe8BG0yUbGkfOCv87pzZohCCF+RKPog/EJkCyd1RLnwU70f92X1XWjO/DoJ7p2csvXSy3RTXU9GzWTr/svo2wb29gGM+pqb1yc+whJfc1w1lFI43q6s4t+EiIac59rC7Xmp69AkUROW0hK2Pe0tIX9DX9xe9YgtYR6Z25e1d+HIVnHCWKv1JROO3PXCY8NX8XmnxD1R6MwxazJOo/2oSZlAgct3Ze2CIyVr+8vMv5lIwkx0neuwtYbkPueNL4+yzK8XSsZ749r1RYvlq9Rz3FqV9b5snfoCD008lBqu5mEre3+dGbG1Qe2PIYXnVNdek3CtD9Zz1aFO7+552KGsvY1v00b9kdrFt+IrOblhOfvyTRlN5v85rasYzdfrh8FvJox9/m3QD9w9HwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANzJP/MdJGqyWhqXAAAAAElFTkSuQmCC'
                          }
                          alt="a"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
            </Grid>
          ) : (
            <RenderSkeleton />
          )}
        </Grid>
      </Grid>
    );
  };
  const [selectedProduct, setSelectedProduct] = React.useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const style_code = query.get('style_code');
    if (!style_code) return;
    setSelectedProduct({ style_code });
    setOpen(true);
  }, [location]);
  const handleClose = () => {
    setOpen(false);
  };
  const { params } = useRouteMatch();

  const { delivery_id } = params;
  const handleLoadProducts = useCallback(async () => {
    setLoading(true);
    const products = await loadProducts({
      delivery_id,
    });

    let productsFiltered = [];

    if (products)
      productsFiltered = products.filter(
        product =>
          product.style_collection ===
            productObjectToFilter[currentCollection.current] &&
          product.status === 'Available'
      );

    const main = [];
    const capsula = [];
    const prespring = [];
    if (!document.location.pathname.includes('delivery')) {
      setCapsulaProducts([]);
    } else {
      productsFiltered.forEach(product =>
        product.status === 'Available'
          ? product.capsula === 'MAIN COLLECTION'
            ? main.push(product)
            : product.capsula === 'PRE SPRING'
            ? prespring.push(product)
            : capsula.push(product)
          : null
      );

      console.log('main', main);
      console.log('capsula', capsula);
      setOrderedProducts(main);
      setCapsulaProducts(capsula);
      setPreSpringProducts(prespring);

      setAllProducts([...main, ...capsula, ...prespring]);
      return setLoading(false);
    }
    setAllProducts([]);
    setOrderedProducts(productsFiltered);
    setLoading(false);
  }, [delivery_id]);

  useEffect(() => {
    handleLoadProducts();
  }, [delivery_id, handleLoadProducts, url]);

  const RenderSkeleton = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {skeletonArray.map(item => (
            <Grid
              key={item.id}
              style={{ display: 'inline-block', position: 'relative' }}
            >
              <Skeleton
                variant="rect"
                style={{
                  margin: '1px 2px',
                  width: 252,
                  height: 380,
                  cursor: 'pointer',
                }}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const saveProductsWithNewOrder = useCallback(
    async products => {
      if (loading) return;
      await saveNewOrderDeliveries({ products });
      await handleLoadProducts();
      handleShowDND();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleLoadProducts, showDND, loading]
  );

  const handlePrevProduct = useCallback(() => {
    const products = allProducts.length > 0 ? allProducts : orderedProducts;
    const selectedProductIndex = products.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex === 0) {
      // vou pro ultimo item
      const product = products.find(
        (p, index) => index === products.length - 1
      );
      // setSelectedProduct(product);
      history.replace({ search: `?style_code=${product.style_code}` });
    } else {
      // volto um item
      const product = products.find(
        (p, index) => index === selectedProductIndex - 1
      );
      history.replace({ search: `?style_code=${product.style_code}` });

      // setSelectedProduct(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, history, orderedProducts, allProducts, delivery_id]);

  const handleNextProduct = useCallback(() => {
    const products = allProducts.length > 0 ? allProducts : orderedProducts;
    const selectedProductIndex = products.findIndex(
      p => p.style_code === selectedProduct.style_code
    );
    if (selectedProductIndex >= products.length - 1) {
      setSelectedProduct(products[0]);
      history.replace({
        search: `?style_code=${products[0].style_code}`,
      });
    } else {
      const product = products.find(
        (p, index) => index === selectedProductIndex + 1
      );
      // setSelectedProduct(product);
      history.replace({ search: `?style_code=${product.style_code}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProduct,
    orderedProducts,
    allProducts,
    history,
    nordProducts,
    delivery_id,
  ]);

  return (
    show && (
      <Fade in timeout={300}>
        <div>
          {bg ? (
            <Grid style={{ position: 'relative', top: window.innerHeight / 4 }}>
              <Typography
                align="center"
                style={{
                  fontSize: 48,
                  color: '#ffffff',
                  letterSpacing: '0.1em',
                }}
              >
                {document.location.pathname.includes('shoes')
                  ? 'Shoes'
                  : document.location.pathname.includes('4')
                  ? 'Fall Winter 24 Ski'
                  : seasonName}
              </Typography>

              <Typography
                align="center"
                style={{
                  fontSize: 28,
                  color: '#ffffff',
                  letterSpacing: '0.1em',
                }}
              />
              <Typography
                align="center"
                style={{
                  color: '#fff',
                  marginTop: 45,
                  fontSize: 24,
                  letterSpacing: '0.1em',
                }}
              >
                {document.location.pathname.includes('weddingcapsule')
                  ? 'WEDDING CAPSULE'
                  : delivery_id !== 'smiley' && delivery_id !== 'nordstrom'
                  ? `DELIVERY ${delivery_id.charAt(0).toUpperCase() +
                      delivery_id.slice(1)}`
                  : delivery_id !== 'smiley'
                  ? 'NORDSTROM ANNIVERSARY'
                  : 'PARTNERSHIP SMILEY'}
              </Typography>

              <Typography
                align="center"
                variant="h6"
                style={{
                  color: '#fff',
                  fontSize: 20,
                  letterSpacing: '0.1em',
                }}
              >
                {document.location.pathname.includes('weddingcapsule')
                  ? deliveries[1].subTitle2.toUpperCase()
                  : document.location.pathname.includes('delivery')
                  ? deliveries[delivery_id - 1].subTitle2.toUpperCase()
                  : document.location.pathname.includes('shoes')
                  ? shoes[delivery_id - 1].subTitle2.toUpperCase()
                  : swimwear[delivery_id - 1].subTitle2.toUpperCase()}
              </Typography>

              <Typography
                align="center"
                variant="h6"
                style={{
                  color: '#fff',
                  fontSize: 20,
                  letterSpacing: '0.1em',
                }}
              >
                {document.location.pathname.includes('weddingcapsule')
                  ? deliveries[1].subTitle1
                  : document.location.pathname.includes('delivery')
                  ? deliveries[delivery_id - 1].subTitle1
                  : document.location.pathname.includes('shoes')
                  ? shoes[delivery_id - 1].subTitle1.toUpperCase()
                  : swimwear[delivery_id - 1].subTitle1}
              </Typography>
            </Grid>
          ) : (
            <>
              <Typography
                align="center"
                style={{ fontSize: 32, letterSpacing: '0.2em', marginTop: 200 }}
              >
                {document.location.pathname.includes('shoes')
                  ? 'shoes'
                  : seasonName}
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: 28, letterSpacing: '0.2em' }}
              >
                COLLECTION
              </Typography>
            </>
          )}

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              style={{
                paddingLeft: 15,
                width: 1044,
                marginTop:
                  window.innerHeight > 750
                    ? window.innerHeight / 4
                    : window.innerHeight / 8,
                height: '100%',
                // marginTop: window.innerHeight / 4,
              }}
            >
              <RenderProducts />
            </Grid>
          </Grid>

          <ModalProduct
            products={orderedProducts}
            // setProducts={setProducts}
            handlePrevProduct={handlePrevProduct}
            handleNextProduct={handleNextProduct}
            open={open}
            handleClose={handleClose}
            style_code={selectedProduct}
          />
        </div>
      </Fade>
    )
  );
}

export default DeliveryView;
